import React, { useEffect, useRef } from 'react';
import type { AppProps /*, AppContext*/ } from 'next/app';
import Head from 'next/head';
import smoothscroll from 'smoothscroll-polyfill';
import Router from 'next/router';
import {
	GlobalContext,
	useGlobalContextValue,
} from '@superhuit/starterpack-context';
import { FPixelPageView } from '@/lib/fpixel';

import '@/css/styles/index.css';

function MyApp({ Component, pageProps }: AppProps) {
	const globalContextValue = useGlobalContextValue();

	const previousFBPixel = useRef(null); // Used to prevent FB Pixel PageView to be fired twice

	// Initiate polyfills
	useEffect(() => {
		smoothscroll.polyfill();
	}, []);

	// Handle route change for gdpr services
	useEffect(() => {
		Router.events.on('routeChangeComplete', handleRouteChange);

		return () => {
			Router.events.off('routeChangeComplete', handleRouteChange);
		};
	}, [globalContextValue?.gdprServices]);

	// Facebook Pixel :: needs to trigger pageview on first time the cookie has been accepted (not only on route change)
	useEffect(() => {
		if (
			previousFBPixel.current?.isAccepted === false &&
			globalContextValue?.gdprServices?.facebookpixel?.isAccepted
		)
			FPixelPageView();

		previousFBPixel.current =
			globalContextValue?.gdprServices?.facebookpixel;
	}, [globalContextValue?.gdprServices?.facebookpixel]);

	const handleRouteChange = (url: string) => {
		// Handle GTM
		// if (globalContextValue?.gdprServices?.gtm?.isAccepted) GTMPageView(url); // no need anymore with Google Analytics 4 (see https://www.dropbox.com/s/z1k9ttaad5jmqko/Screen%20Recording%202021-03-29%20at%2015.40.35.mov?dl=0)

		// Handle Facebook Pixels
		if (globalContextValue?.gdprServices?.facebookpixel?.isAccepted)
			FPixelPageView();
	};

	return (
		<GlobalContext.Provider value={globalContextValue}>
			<Head>
				{/* Google Tag Manager */}
				{globalContextValue?.gdprServices?.googletagmanager
					?.isAccepted &&
				globalContextValue?.gdprServices?.googletagmanager
					?.containerID ? (
					<>
						<script
							dangerouslySetInnerHTML={{
								__html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
								new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
								j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
								'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
								})(window,document,'script','dataLayer','${globalContextValue?.gdprServices?.googletagmanager?.containerID}');`,
							}}
						/>
					</>
				) : null}

				{/* Facebook Pixel */}
				{globalContextValue?.gdprServices?.facebookpixel?.isAccepted &&
				globalContextValue?.gdprServices?.facebookpixel?.pixelID ? (
					<>
						<script
							dangerouslySetInnerHTML={{
								__html: `
									!function(f,b,e,v,n,t,s)
									{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
									n.callMethod.apply(n,arguments):n.queue.push(arguments)};
									if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
									n.queue=[];t=b.createElement(e);t.async=!0;
									t.src=v;s=b.getElementsByTagName(e)[0];
									s.parentNode.insertBefore(t,s)}(window, document,'script',
									'https://connect.facebook.net/en_US/fbevents.js');
									fbq('init', ${globalContextValue?.gdprServices?.facebookpixel?.pixelID});
								`,
							}}
						/>
					</>
				) : null}
			</Head>
			<Component {...pageProps} />
		</GlobalContext.Provider>
	);
}

export default MyApp;
