declare global {
	interface Window {
		fbq: any;
	}
}

export const FPixelPageView = () => {
	window && window.fbq && window.fbq('track', 'PageView');
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const FPixelEvent = (name: string, options: object = {}) => {
	window && window.fbq && window.fbq('track', name, options);
};
